<template >
  <div class="video-fiche">
    <div class="video-box video-model" v-if="isplayShow">
      <van-overlay :show="isplayShow" @click="isplayClick(false)">
        <video
          v-if="item.videoAddress"
          ref="video"
          class="video-w video-js vjs-default-skin vjs-big-play-centered"
          controls
          :muted="false"
          :poster="item.videoAddress + '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,ar_auto'"
        >
          <source :src="item.videoAddress" />
        </video>
        <div class="suspend g-align-center" v-if="isplay">
          <img :class="[typePrice == 1 ? 'pause-img':'pause-img2']" src="@/assets/images/pause-img.png" alt="" @click.stop="hendlePlay">
        </div>
      </van-overlay>
    </div>
  </div>
</template>
<script>
export default {
  name: 'video-fiche',
  props: {
    value: {
      type: [String, Object, Boolean],
      default: ''
    },
    item: {
      type: [String, Object, Boolean],
      default: ''
    },
    typePrice: {
      type: [Number, Object, Boolean],
      default: 1
    }
  },
  data () {
    return {
      isplay: true,
      isplayShow: false
    }
  },
  watch: {
    value: {
      handler (newName) {
       if (newName) {
        this.isplayShow = this.value
          let that = this
          this.$nextTick(() => {
            that.$refs.video.addEventListener('play', that.hendlePlay)
            that.$refs.video.addEventListener('pause', that.hendlePause)
            that.hendlePlay()
          })
       }
      }
    }
  },
  mounted() {
  },
  methods: {
    isplayClick (val) {
      this.isplayShow = val
      if (!val) {
        this.hendlePause()
      }
      this.$emit('input', val)
    },
    hendlePlay() {
      this.$refs.video.play()
      this.isplay = false
    },
    hendlePause() {
      // this.$refs.video.pause()
      this.isplay = true
    }
  }
}
</script>
<style lang="less" scoped>

.video-fiche {
  height: 100%;
  .video-none {
    //所有控件
    video::-webkit-media-controls-enclosure{
      display: none;
    }
  }
  .video-box {
    position: relative;
    width: 100%;
    // height: 20.7rem;
    height: 100%;
    .video-w {
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .suspend {
      position: absolute;
      top: 30%;
      left: 25%;
      width: 50%;
      height: 40%;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .pause-img {
        position: relative;
        z-index: 999;
        width: 70px;
        height: 70px;
        margin: 0 auto;
        border-radius: 50%;
        // background: rgba(255, 255, 255, 1);
      }
      .pause-img2 {
        width: 20px;
        height: 20px;
      }
    }
  }
  .video-model {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 999;
    .van-overlay {
      display: flex;
      align-items: center;
    }
    .video-w {
      height: 32%;
    }
    //播放按钮
    // video::-webkit-media-controls-play-button {
    //     display: none;
    // }
    // video::-webkit-media-controls {
    //   display:none !important;
    // }
  }
}

</style>
